import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import styles from './Modal.module.scss'
import { Box } from 'condolivre-ds'

export default function Modal({
    show,
    onClose,
    children,
    id,
    className,
    backdropClassName,
    withCloseButton,
    ...props
}) {
    const ref = useRef()
    const handleClickBackdrop = (e) => {
        const target = e.target || e.srcElement
        if (target === ref.current) {
            onClose()
        }
    }
    return show ? (
        <div
            ref={ref}
            className={[styles.backdrop, backdropClassName].join(' ')}
            onClick={handleClickBackdrop}
            role="none"
            data-testid={`${id}-blackdrop`}
            id={id}>
            <Box
                className={[styles.modalContent, className].join(' ')}
                data-testid={`${id}-modal-content`}
                {...props}>
                {children}
                {withCloseButton && (
                    <button
                        className={styles.closeButton}
                        onClick={onClose}
                        data-testid={`${id}-close-button`}>
                        <b>X</b>
                    </button>
                )}
            </Box>
        </div>
    ) : (
        <></>
    )
}

Modal.defaultProps = {
    show: false,
    onClose: () => {},
    backdropClassName: '',
    withCloseButton: false
}

Modal.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    show: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.any,
    backdropClassName: PropTypes.string,
    withCloseButton: PropTypes.bool
}
