import 'nprogress/nprogress.css'
import NProgress from 'nprogress'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { registerDigitalAccountCookies } from './cookies'
import jwtDecode from 'jwt-decode'

const service = axios.create({
    baseURL: process.env.API_URL,
    delayed: true
})

// Add a request interceptor
service.interceptors.request.use(
    function (config) {
        if (
            config.headers.url !== '/account/sign-in-with-email' &&
            config.url !== '/baas/auth/login'
        ) {
            const cookies = new Cookies()

            const token = cookies.get('da-session')
            config.headers.Authorization = 'Bearer ' + token
        }
        NProgress.start()

        return config
    },
    function (error) {
        NProgress.done()
        return Promise.reject(error)
    }
)

// Add a response interceptor
service.interceptors.response.use(
    function (response) {
        try {
            const access_token =
                response.headers['access-token'] ?? response.headers['access_token']

            if (access_token) {
                const data = jwtDecode(access_token)
                registerDigitalAccountCookies(access_token, new Date(data.exp * 1000), data.audit)
            }
        } catch {
            // Could not refresh token, continue with the request
        }

        NProgress.done()
        return response
    },
    function (error) {
        NProgress.done()
        if (error.response.status === 401) {
            const cookies = new Cookies()

            cookies.remove('da-session')
            localStorage.removeItem('name')
            window.location.href = '/digital-account/new-login'
        }
        return Promise.reject(error)
    }
)

export default service
