import apiClient from '@/helpers/service'
import { handleResponseError } from '@/helpers'
import { enhanceDigitalAccountUser, registerCookie } from '@/helpers/cookies'

export const requestsLogin = async (document, password) => {
    try {
        const response = await apiClient.post(`auth/account/sign-in/supply`, {
            document,
            password
        })
        const { access_token, expires_in } = response.data
        return {
            success: true,
            data: {
                token: access_token,
                expires_in
            }
        }
    } catch (err) {
        return handleResponseError(err)
    }
}

export const requestUserName = async () => {
    try {
        const response = await apiClient.get('/supply/suppliers/me')

        return {
            success: true,
            data: response.data
        }
    } catch (err) {
        return handleResponseError(err)
    }
}

export const authenticate = async (email, password) => {
    try {
        const response = await requestsLogin(email, password)
        if (response.success) {
            const { token, expires_in } = response.data
            registerCookie(token, new Date(expires_in))
            const userNameResponse = await requestUserName()
            if (!userNameResponse.success) {
                return userNameResponse
            }
            enhanceDigitalAccountUser({
                email,
                ...userNameResponse.data
            })
        }
        return response
    } catch (err) {
        return handleResponseError(err)
    }
}

export const requestForgotPassword = async (cnpj) => {
    try {
        const response = await apiClient.put('/auth/account/forgot-password/supply/key', {
            key: cnpj
        })
        const { email } = response.data
        return {
            success: true,
            data: {
                email: email
            }
        }
    } catch (err) {
        return handleResponseError(err)
    }
}

export const requestAvailableChangeAccount = async () => {
    try {
        const response = await apiClient.post('/baas/auth/pre-change-account')

        const bffSuccess = response.data?.success
        const serverSuccess = response.data?.data?.success

        if (!bffSuccess || !serverSuccess) {
            return {
                success: false,
                force_logout: true
            }
        }

        return {
            success: true,
            data: response.data.data
        }
    } catch (err) {
        return handleResponseError(err)
    }
}

export const requestChangeAccount = async (account_id, audit) => {
    try {
        const response = await apiClient.post('/baas/auth/change-account', {
            account_id,
            audit
        })

        const bffSuccess = response.data?.success
        const serverSuccess = response.data?.data?.success

        if (!bffSuccess || !serverSuccess) {
            return {
                success: false,
                force_logout: true
            }
        }

        const {
            access_token,
            access_token_expires_in,
            first_login,
            should_set_transaction_password
        } = response.data?.data

        return {
            success: true,
            first_login,
            access_token,
            access_token_expires_in,
            audit,
            should_set_transaction_password
        }
    } catch (err) {
        return handleResponseError(err)
    }
}
