import jwt_decode from 'jwt-decode'
import Cookies from 'universal-cookie'

export const registerCookie = (token, expires_in) => {
    const cookies = new Cookies()

    cookies.set('session', token, {
        expires: expires_in,
        path: '/',
        sameSite: 'strict'
    })

    const decoded = jwt_decode(token)
    cookies.set('user', JSON.stringify(decoded), {
        expires: expires_in,
        path: '/',
        sameSite: 'strict'
    })
}

export const registerDigitalAccountCookies = (token, expires_in, audit) => {
    const cookies = new Cookies()

    cookies.set('da-session', token, {
        expires: expires_in,
        path: '/',
        sameSite: 'strict'
    })

    const decoded = jwt_decode(token)
    enhanceDigitalAccountUser(decoded, expires_in)

    audit &&
        cookies.set('da-audit', audit, {
            expires: expires_in,
            path: '/',
            sameSite: 'strict'
        })
}

export const registerLelloCookies = (token) => {
    const cookies = new Cookies()

    const decoded = jwt_decode(token)
    const expiresIn = new Date(1970, 0, 1)
    expiresIn.setSeconds(decoded.exp)

    cookies.set('da-session', token, {
        expires: expiresIn,
        path: '/',
        sameSite: 'strict'
    })

    cookies.set(
        'da-user',
        JSON.stringify({
            ...decoded,
            expires_in: expiresIn
        }),
        {
            expires: expiresIn,
            path: '/',
            sameSite: 'strict'
        }
    )
}

export const getUser = () => {
    return getDigitalAccountUser()
}

export const getDigitalAccountUser = () => {
    const cookies = new Cookies()
    return cookies.get('da-user')
}

export const enhanceDigitalAccountUser = (payload, expires_in) => {
    const user = getDigitalAccountUser()

    const newUser = {
        ...user,
        ...payload
    }

    const cookies = new Cookies()
    cookies.set('da-user', JSON.stringify(newUser), {
        expires: expires_in ?? (user?.expires_in ? new Date(user.expires_in) : undefined),
        path: '/',
        sameSite: 'strict'
    })
}

export const registerDismissedMessage = (type) => {
    const cookies = new Cookies()
    cookies.set('dismissedMessage', JSON.stringify(type), {
        expires: new Date('9999-12-31'),
        path: '/',
        sameSite: 'strict'
    })
}

export const getDismissedMessage = () => {
    const cookies = new Cookies()
    return cookies.get('dismissedMessage')
}
